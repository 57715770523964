<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class='pb-5'>
          <div class="d-flex justify-content-between align-items-center px-4 pt-4">
            <h3 class="card-title font-weight-bold" style='font-size: 30px'>Artículos</h3>
            <article class="d-flex justify-content-between align-items-center">
              <vs-button @click="$router.push('NuevoArticulo')">Nuevo Artículo</vs-button>
              <vs-button class='bg-success'>Inventario</vs-button>
            </article>
          </div>
          <div class='p-5'>
            <vs-input v-model="search" border placeholder="Search" />
            <div class="table-responsive mt-3">
              <table class="table table-bordered table-responsive-md table-striped text-center">
                <thead>
                  <tr>
                    <th style='max-width: 5rem; cursor: pointer;' @click.prevent='ordenar("Nombre")'>
                      Nombre
                    </th>
                    <th style='min-width: 12rem; cursor: pointer;' @click.prevent='ordenar("Descripcion")'>
                      Descripción
                    </th>
                    <th style='cursor: pointer;' @click.prevent='ordenar("Tipo")'>
                      Tipo
                    </th>
                    <th style='cursor: pointer;' @click.prevent='ordenar("Categoria")'>
                      Categoría
                    </th>
                    <th style='cursor: pointer;' @click.prevent='ordenar("Stock")'>
                      Stock
                    </th>
                    <th style='min-width: 8rem; cursor: pointer;' @click.prevent='ordenar("PrecioVenta")'>
                      Precios de Venta
                    </th>
                    <th style='min-width: 9rem; cursor: pointer;' @click.prevent='ordenar("PrecioCompra")'>
                      Precios de Compra
                    </th>
                    <th style='width: 6rem;'>
                      Opciones
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in article" :key="item.id">
                    <td class='font-weight-bold'>
                      {{ item.Nombre }}
                    </td>
                    <td>
                      <b-button class='p-0 w-100' style='text-align: start;' variant="trash-outline" v-b-tooltip.hover
                        :title="item.Descripcion">
                        {{ item.Descripcion.length > 50 ? item.Descripcion.substring(0, 50) + '...' : item.Descripcion
                        }}
                      </b-button>
                    </td>
                    <td>
                      {{ item.Tipo }}
                    </td>
                    <td>
                      {{ item.Categoria }}
                    </td>
                    <td>
                      {{ item.Stock }}
                    </td>
                    <td>
                      {{ item.PrecioVenta }}
                    </td>
                    <td>
                      {{ item.PrecioCompra }}
                    </td>
                    <td>
                      <div class='d-flex justify-content-center' style='gap: .5rem;'>
                        <b-button v-b-tooltip.hover title='ver' class='bg-success border-0'>
                          <ion-icon name="eye-outline"></ion-icon>
                        </b-button>
                        <b-button class='bg-primary border-0' v-b-tooltip.hover title='Editar'
                          @click="$router.push(`EditarArticulo/${item.Id}`)">
                          <ion-icon name="create-outline"></ion-icon>
                        </b-button>
                        <b-button @click='deleteArticulo(item.Id)' class='bg-danger border-0' v-b-tooltip.hover
                          title='Eliminar'>
                          <ion-icon name="trash-outline"></ion-icon>
                        </b-button>
                      </div>
                    </td>
                  </tr>
                  <tr v-if='loading' style='height: 10rem;'>
                    <td colspan='8' class='w-100'>Buscando registros</td>
                  </tr>
                  <tr v-else-if='article.length === 0' style='height: 10rem;'>
                    <td colspan='8' class='w-100'>No hay artículos</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class='d-flex justify-content-between align-items-center w-100'>
              <b-form-select style='width: 4rem;' placeholder='-- 10 --' :options="options" v-model='max' required
                value='10'>
              </b-form-select>
              <vs-pagination v-model="page" :length="totalpagues" />
            </div>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import { core } from "../../../../../config/pluginInit";
  import Swal from 'sweetalert2';
  export default {
    data: () => ({
      article: [],
      empresaId: null,
      search: '',
      page: 1,
      totalpagues: 0,
      max: 10,
      loading: false,
      columna: "",
      ascendente: true,
      options: [
        {
          value: 5,
          text: '5'
        },
        {
          value: 10,
          text: '10'
        }, {
          value: 20,
          text: '20'
        }, {
          value: 50,
          text: '50'
        }, {
          value: 100,
          text: '100'
        }
      ],
    }),
    async mounted() {
      const valor = JSON.parse(localStorage.getItem('setPersonaAct'));
      if (valor) {
        this.empresaId = valor.empresa.id;
      }

      core.index();
      this.article = [];
      await this.getData();
    },
    methods: {
      async getData() {
        this.article = []
        let send = {
          filtro: this.search,
          orden: {
            columna: this.columna,
            ascendente: this.ascendente
          },
          cantDatos: this.max,
          paginacion: this.page
        }
        try {
          this.loading = true;
          const res = await this.$store.dispatch('hl_get', {
            path: `CONTArticulo/ObtenerCantidadArticulosId/${this.empresaId}`
          })

          this.totalpagues = Math.ceil(res.data / this.max);
          if (res.data <= this.max) {
            this.page = 1;
          }

          const response = await this.$store.getters.fetchPost({
            path: 'CONTArticulo/ObtenerArticuloEmpresaId/' + this.empresaId,
            data: send
          });
          if (response.ok) {
            const data = await response.json();
            console.log('fatosa',data);
            if (data.data.length > 0) {
              this.article = data.data.map((items) => items)
            } else {
              this.Registro = []
            }
            
          } else {
            console.error('Error en la solicitud:', response.statusText);
          }
        } catch (error) {
          this.article = []
        } finally {
          this.loading = false;
        }
        console.log("datos", this.article);
      },
      async deleteArticulo(id) {
        await Swal.fire({
          title: '¿Estás seguro que deseas eliminar el Articulo?',
          text: "¡No podrás revertir esta acción!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, borrarlo!'
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await this.$store.dispatch("hl_delete", {
                path: `CONTArticulo/EliminarArticulo/${id}`,
              });
              Swal.fire("Listo!", "Articulo eliminado Correctamente", "success");
              await this.getData();
            } catch (error) {
              Swal.fire("Error", "Hubo un problema al eliminar el Articulo", "error");
            }
          }
        });
      },
      ordenar(columna) {
        if (this.columna === columna) {
          this.ascendente = !this.ascendente;
        } else {
          this.columna = columna;
          this.ascendente = true;
        }
        this.getData();
      }
    }
  }
</script>

<style>
  .vs-input {
    width: 100%;
  }
</style>
